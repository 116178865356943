import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { colors } from "components/Theme/WebTheme.js";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutUs(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div style={{ 
          fontFamily: 'Arial, sans-serif', 
          lineHeight: 1.6, 
          color: '#333', 
          padding: 20 
        }}>
          <p>Welcome to our taxi service based in southwest suburbs of Chicago.</p>

          <p>
            We are a trusted and reliable transportation company providing top-quality taxi services to Airport drop off and pickups alike. 
            With our fleet of well-maintained vehicles and professional drivers, we are committed to making your travel experience comfortable, safe, and hassle-free.
          </p>

          <p>
            Our services include airport pickups, and drops, and corporate car rentals. Whether you need a ride to the airport, a quick ride across town, 
            our taxis are available 24/7 to cater to all your transportation needs.
          </p>

          <p>
            Our team of experienced drivers are not only well-versed with the roads and traffic of Chicago, but they also provide courteous and professional service, 
            ensuring you arrive at your destination on time and in comfort. 
            Our vehicles are well-maintained, clean, and equipped with amenities such as air conditioning, GPS, and entertainment systems to make your ride as enjoyable as possible.
          </p>

          <p>
            At Outstation Cabs, we understand that each customer has unique needs, which is why we offer a range of options to suit your requirements. 
            You can choose from our wide variety of vehicles including hatchbacks, sedans, and SUVs or Minivan. 
            We also offer customized packages, so whether you're traveling for business or leisure, we've got you covered.
          </p>

          <p>
            Booking a ride with us is simple and easy. You can book your ride by calling us or just dropping us a WhatsApp message on our Helpline No.{' '}
            <strong>+1 6589888887</strong>.
          </p>

          <p>
            We also offer a round-the-clock customer support team to assist you with any queries or concerns.
          </p>

          <p>
            In addition to providing exceptional taxi services, we are also committed to the safety and security of our customers. 
            Our vehicles are equipped with real-time GPS tracking systems, and we ensure that all our drivers undergo a thorough background check before they are hired.
          </p>

          <p>
            If you need a reliable and affordable taxi service in southwest suburbs of Chicago, look no further than our taxi service. 
            Book your ride with us today and experience the convenience and comfort of our friendly taxi service.
          </p>

          <p>
            Best service as exactly that it is quick response, very friendly and professional care about comfort, pricing is very fair and affordable 
            would totally recommend and feel all about Taxi2Fly 100% satisfaction to make all about the customer.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
