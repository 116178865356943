import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Avatar, Button, Card, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { api } from "common";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FONT_FAMILY, MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import AlertDialog from "../components/AlertDialog";
import CircularLoading from "../components/CircularLoading";
import { colors } from "../components/Theme/WebTheme";
const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "10px",
    backgroundColor: SECONDORY_COLOR,
    minHeight: 60,
    minWidth: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: 2,
    marginBottom: 10,
    boxShadow: `0px 2px 5px ${MAIN_COLOR}`,
  },
  txt: {
    padding: 10,
    fontWeight: "bold",
    minHeight: 60,
    backgroundColor: SECONDORY_COLOR,
    color: colors.LandingPage_Background,
    boxShadow: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: FONT_FAMILY
  },
  buttonStyle: {
    borderRadius: "19px",
    minHeight: 50,
    minWidth: 150,
    marginBottom: 20,
    marginTop: 20,
    textAlign: "center",
    cursor: "pointer",
    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
    fontFamily: FONT_FAMILY
  },
  avatar: {
    width: 300,
    height: 250,
    display: "flex",
    flexDirection: "column",
    boxShadow: 3,
    border: `2px dashed ${colors.TABLE_BORDER}`,
    fontSize: 16,
    background: "none",
    color: "inherit",
    fontWeight: "bold",
  }
}));

function UserDocuments() {
  const { id, rId } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const { fetchUsersOnce, updateLicenseImage, editUser } = api;
  const [data, setData] = useState([]);
  const staticusers = useSelector((state) => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const IdInputRef = useRef();
  const licenseImageFrontInputRef = useRef();
  const RegistrationImageFrontInputRef = useRef();
  const BackgroundcheckImageFrontInputRef = useRef();
  const SafetylineinspectionImageFrontInputRef = useRef();
  const carInsuranceImageInputRef = useRef();
  const licenseImageBackInputRef = useRef();
  const loaded = useRef(false);
  const [editable, setEditable] = useState(false);
  const [idImage, setIdImage] = useState(null);
  const [carInsuranceImage, setcarInsuranceImage] = useState(null);
  const [RegistrationImageFront, setRegistrationImageFront] = useState(null);
  const [BackgroundcheckImageFront, setBackgroundcheckImageFront] = useState(null);
  const [SafetylineinspectionImageFront, setSafetylineinspectionImageFront] = useState(null);
  const [licenseImageFront, setLicenseImageFront] = useState(null);
  const [licenseImageBack, setLicenseImageBack] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const settings = useSelector(state => state.settingsdata.settings);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [carInsuranceExpiryDate, setCarInsuranceExpiryDate] = useState('');
  const [DrivingLicenseExpiryDate, setDrivingLicenseExpiryDate] = useState(''); 
  const [RegistrationExpiryDate, setRegistrationExpiryDate] = useState('');
  const [BackgroundcheckExpiryDate, setBackgroundcheckExpiryDate] = useState('');
  const [SafetylineinspectionExpiryDate, setSafetylineinspectionExpiryDate] = useState('');
  const [oldData, setOldData] = useState(null); 

  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  useEffect(() => {

    if (staticusers) {
      const user = staticusers.filter((user) => user.id === id.toString())[0];
      if (!user) {
        navigate("/404");
      }
      setData(user);
      setOldData(user); 
      setCarInsuranceExpiryDate(user.CarInsuranceExpiryDate);
      setDrivingLicenseExpiryDate(user.DrivingLicenseExpiryDate);
      setRegistrationExpiryDate(data.RegistrationExpiryDate);
      setBackgroundcheckExpiryDate(data.BackgroundcheckExpiryDate);
      setSafetylineinspectionExpiryDate(data.SafetylineinspectionExpiryDate); 

    } else {
      setData([]);
    }
    loaded.current = true;
  }, [staticusers, id, navigate, data]);

  const IdImageChange = async (e) => {
    setIdImage(e.target.files[0]);
  };
  const licenseImageBackChange = async (e) => {
    setLicenseImageBack(e.target.files[0]);
  };
  const licenseImageFrontChange = async (e) => {
    setLicenseImageFront(e.target.files[0]);
  };
  const RegistrationImageFrontChange = async (e) => {
    setRegistrationImageFront(e.target.files[0]);
  };
  const BackgroundcheckImageFrontChange = async (e) => {
    setBackgroundcheckImageFront(e.target.files[0]);
  };
  const SafetylineinspectionImageFrontChange = async (e) => {
    setSafetylineinspectionImageFront(e.target.files[0]);
  };
  const carInsuranceImageChange = async (e) => {
    setcarInsuranceImage(e.target.files[0]);
  };
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const onDateChange = (event) => { 
    const newExpiryDate = event.target.value;
    setCarInsuranceExpiryDate(newExpiryDate); 
  };
  const DrivingLicenseExpiryDateChange = (event) => { 
    const newExpiryDate = event.target.value;
    setDrivingLicenseExpiryDate(newExpiryDate); 
  };
  const RegistrationDateChange = (event) => { 
    const newExpiryDate = event.target.value;
    setRegistrationExpiryDate(newExpiryDate); 
  }; const SafetylineinspectionExpiryDateChange = (event) => { 
    const newExpiryDate = event.target.value;
    setSafetylineinspectionExpiryDate(newExpiryDate); 
  }; const BackgroundcheckExpiryDateChange = (event) => { 
    const newExpiryDate = event.target.value;
    setBackgroundcheckExpiryDate(newExpiryDate); 
  };

  const handleSaveUser = () => {
    setLoading(true);
    if (settings.AllowCriticalEditsAdmin) {

      if (idImage) {
        dispatch(updateLicenseImage(data.id, idImage, "verifyIdImage")).then(
          () => {
            dispatch(fetchUsersOnce());
          }
        )
      }

      if (carInsuranceImage) {
        dispatch(updateLicenseImage(data.id, carInsuranceImage, "carInsuranceImage")).then(
          () => {
            dispatch(fetchUsersOnce());
          }
        )
      }

      if (licenseImageFront) {
        dispatch(updateLicenseImage(data.id, licenseImageFront, "licenseImage")).then(
          () => {
            dispatch(fetchUsersOnce());
          }
        )
      }
      if (licenseImageBack) {
        dispatch(
          updateLicenseImage(data.id, licenseImageBack, "licenseImageBack")
        ).then(
          () => {
            dispatch(fetchUsersOnce());
          }
        )
      } if (RegistrationImageFront) {
        dispatch(updateLicenseImage(data.id, RegistrationImageFront, "RegistrationImage")).then(
          () => {
            dispatch(fetchUsersOnce());
          }
        )
      } if (BackgroundcheckImageFront) {
        dispatch(updateLicenseImage(data.id, BackgroundcheckImageFront, "BackgroundcheckImage")).then(
          () => {
            dispatch(fetchUsersOnce());
          }
        )
      } if (SafetylineinspectionImageFront) {
        dispatch(updateLicenseImage(data.id, SafetylineinspectionImageFront, "SafetylineinspectionImage")).then(
          () => {
            dispatch(fetchUsersOnce());
          }
        )
      }
      if (
        data.usertype === "driver" &&
        !licenseImageBack &&
        !licenseImageFront &&
        !SafetylineinspectionImageFront &&
        !BackgroundcheckImageFront &&
        !RegistrationImageFront &&
        !carInsuranceImage &&
        !idImage
      ) {
        setCommonAlert({ open: true, msg: t("update_profile_title") });
      }
      setTimeout(() => {
        setIdImage(null);
        setLicenseImageFront(null);
        setLicenseImageBack(null);
        setcarInsuranceImage(null);
        setLoading(false);
        setEditable(false);
      }, 3000);
      loaded.current = true;
    } else {
      setLoading(false);
      setCommonAlert({ open: true, msg: t("demo_mode") });
    }
  };

  const handleUpdate = () => { 
    if (settings.AllowCriticalEditsAdmin) {
        setLoading(true);
        const updatedData = {
            ...data,
            CarInsuranceExpiryDate: carInsuranceExpiryDate,
            DrivingLicenseExpiryDate: DrivingLicenseExpiryDate,
            RegistrationExpiryDate: RegistrationExpiryDate,
            BackgroundcheckExpiryDate: BackgroundcheckExpiryDate,
            SafetylineinspectionExpiryDate: SafetylineinspectionExpiryDate,
        };

        if (
            JSON.stringify(oldData) !== JSON.stringify(updatedData) &&
            Object.keys(updatedData).length !== 0
        ) { 
            dispatch(editUser(updatedData.id, updatedData));
            dispatch(fetchUsersOnce());
        }
        setLoading(false);
    } else {
        setCommonAlert({ open: true, msg: t('demo_mode') });
    }
  };

  const handleCancel = () => {
    setIdImage(null);
    setLicenseImageBack(null);
    setLicenseImageFront(null);
    setcarInsuranceImage(null);
    setEditable(false);
  };

  return loading ? (
    <CircularLoading />
  ) : (
    <>
      <div>
        <Card
          style={{
            borderRadius: "19px",
            backgroundColor: colors.WHITE,
            minHeight: 200,
            marginTop: 20,
            marginBottom: 20,
            padding: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: isRTL === "rtl" ? "flex-end" : "flex-start",
            }}
          >
            <Typography
              variant="h5"
              style={{
                margin: "10px 10px 0 5px",
                fontFamily: FONT_FAMILY
              }}
            >
              {t("documents_title")}
            </Typography>
            <div
              style={{
                display: "flex",
              }}
            >
              <Button
                variant="text"
                onClick={() => {
                  navigate(`/users/${rId}`);
                }}
              >
                <Typography
                  style={{
                    margin: "10px 10px 0 5px",
                    textAlign: isRTL === "rtl" ? "right" : "left",
                    fontWeight: "bold",
                    color: MAIN_COLOR,
                    fontFamily: FONT_FAMILY
                  }}
                >
                  {`<<- ${t("go_back")}`}
                </Typography>
              </Button>
            </div>
          </div>

          <Grid
            container
            spacing={1}
            direction="column"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Grid item>
              <Grid
                container
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
                marginY={10}
                gap={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  direction: isRTL === "rtl" ? "rtl" : "ltr",
                }}
              >
                {editable ? (
                  <>

                    {/* Driver carInsurance Image */}
                    {data?.usertype === "driver" ? (
                      <>

                        {/* carInsurance Image */}
                        <>
                          {carInsuranceImage ? (
                            <div
                              onClick={() =>
                                carInsuranceImageInputRef.current.click()
                              }
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 10,
                              }}
                            >
                              <img
                                src={URL.createObjectURL(carInsuranceImage)}
                                alt="Profile"
                                style={{
                                  width: 300,
                                  height: 250,
                                  borderRadius: "19px",
                                }}
                              />
                            </div>
                          ) : ( 
                            <div>
                            <div
                              onClick={() =>
                                carInsuranceImageInputRef.current.click()
                              }
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 30,
                                cursor: "pointer",
                              }}
                            >
                              <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3 }}>
                                <FileUploadIcon
                                  sx={{
                                    fontSize: 100,
                                    marginBottom: 3,
                                    color: colors.Header_Text_back,
                                  }}
                                />
                                {<Typography sx={{ textAlign: "center" }} fontFamily={FONT_FAMILY}>Car Insurance Image</Typography>}
                              </Avatar>
                            </div>
                            <div style={{ marginTop: 20, textAlign: "center" }}>
                              <Typography sx={{ fontFamily: FONT_FAMILY }}>Car Insurance Expiry Date</Typography>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <input
                                    type="date"                
                                    value={carInsuranceExpiryDate}
                                    onChange={onDateChange}
                                    style={{
                                    marginTop: 10,
                                    padding: 5,
                                    borderRadius: "5px",
                                    border: `1px solid ${colors.TABLE_BORDER}`,
                                  }}
                                /> 
                              </div>
                            </div>
                            </div>
                          )}
                          <input
                            onChange={(event) => carInsuranceImageChange(event)}
                            multiple={false}
                            ref={carInsuranceImageInputRef}
                            type="file"
                            hidden
                          /> 
                        </>
                      </>
                    ) : null}

                    {/* Customer Driving License Image */}
                    <>
                      <>
                        {licenseImageFront ? (
                          <div
                            onClick={() =>
                              licenseImageFrontInputRef.current.click()
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 10,
                            }}
                          >
                            <img
                              src={URL.createObjectURL(licenseImageFront)}
                              alt="Profile"
                              style={{
                                width: 300,
                                height: 250,
                                borderRadius: "19px",
                              }}
                            />
                          </div>
                        ) : ( 
                          <div>
                          <div
                            onClick={() =>
                              licenseImageFrontInputRef.current.click()
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 30,
                              cursor: "pointer",
                            }}
                          >
                            <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3 }}>
                              <FileUploadIcon
                                sx={{
                                  fontSize: 100,
                                  marginBottom: 3,
                                  color: colors.Header_Text_back,
                                }}
                              />
                              {<Typography sx={{ textAlign: "center" }} fontFamily={FONT_FAMILY}>{t("upload_driving_license_front")}</Typography>}
                            </Avatar>
                          </div> 
                          
                          <div style={{ marginTop: 20, textAlign: "center" }}>
                            <Typography sx={{ fontFamily: FONT_FAMILY }}>Driving license Expiry Date</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>     
                            <input
                              type="date"
                              onChange={DrivingLicenseExpiryDateChange}
                              value={DrivingLicenseExpiryDate}
                              style={{
                                marginTop: 10,
                                padding: 5,
                                borderRadius: "5px",
                                border: `1px solid ${colors.TABLE_BORDER}`,
                              }}
                            /> 
                              </div>
                          </div>
                          </div>
                        )}
                        <input
                          onChange={(event) => licenseImageFrontChange(event)}
                          multiple={false}
                          ref={licenseImageFrontInputRef}
                          type="file"
                          hidden
                        />
                      </>

                      <>
                        {licenseImageBack ? (
                          <div>
                          <div
                            onClick={() =>
                              licenseImageBackInputRef.current.click()
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 10,
                            }}
                          >
                            <img
                              src={URL.createObjectURL(licenseImageBack)}
                              alt="Profile"
                              style={{
                                width: 300,
                                height: 250,
                                borderRadius: "19px",
                              }}
                            />
                          </div>
                          </div>
                        ) : ( 
                          <div>
                          <div
                            onClick={() =>
                              licenseImageBackInputRef.current.click()
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 30,
                              cursor: "pointer",
                            }}
                          >
                            <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3 }}>
                              <FileUploadIcon
                                sx={{
                                  fontSize: 100,
                                  marginBottom: 3,
                                  color: colors.Header_Text_back,
                                }}
                              />
                              {<Typography sx={{ textAlign: "center" }} fontFamily={FONT_FAMILY}>{t("upload_driving_license_back")}</Typography>}
                            </Avatar>
                          </div>
                          <div style={{
                                marginTop: 72,
                                padding: 5, 
                              }}>

                          </div>
                          </div>
                        )}
                        <input
                          onChange={(event) => licenseImageBackChange(event)}
                          multiple={false}
                          ref={licenseImageBackInputRef}
                          type="file"
                          hidden
                        />
                      </>

                    </>

 
                    {data?.usertype === "driver" ? (
                      <>
                        {/* Registration image */}
                        <>
                          {RegistrationImageFront ? (
                            <div
                              onClick={() =>
                                RegistrationImageFrontInputRef.current.click()
                              }
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 10,
                              }}
                            >
                              <img
                                src={URL.createObjectURL(RegistrationImageFront)}
                                alt="Profile"
                                style={{
                                  width: 300,
                                  height: 250,
                                  borderRadius: "19px",
                                }}
                              />
                            </div>
                          ) : ( 
                            <div>
                            <div
                              onClick={() =>
                                RegistrationImageFrontInputRef.current.click()
                              }
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 30,
                                cursor: "pointer",
                              }}
                            >
                              <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3 }}>
                                <FileUploadIcon
                                  sx={{
                                    fontSize: 100,
                                    marginBottom: 3,
                                    color: colors.Header_Text_back,
                                  }}
                                />
                                {<Typography sx={{ textAlign: "center" }} fontFamily={FONT_FAMILY}> Registration Image</Typography>}
                              </Avatar>
                            </div>
                            <div style={{ marginTop: 20, textAlign: "center" }}>
                            <Typography sx={{ fontFamily: FONT_FAMILY }}>Registration Expiry Date</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> 
                            <input
                              type="date"
                                
                              onChange={RegistrationDateChange}
                              value={RegistrationExpiryDate}
                              style={{
                                marginTop: 10,
                                padding: 5,
                                borderRadius: "5px",
                                border: `1px solid ${colors.TABLE_BORDER}`,
                              }}
                            /> 
                          </div>
                          </div>
                            </div>
                          )}
                          <input
                            onChange={(event) => RegistrationImageFrontChange(event)}
                            multiple={false}
                            ref={RegistrationImageFrontInputRef}
                            type="file"
                            hidden
                          />
                        </>
                        {/* Background check image */}
                        <>
                          {BackgroundcheckImageFront ? (
                            <div
                              onClick={() =>
                                BackgroundcheckImageFrontInputRef.current.click()
                              }
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 10,
                              }}
                            >
                              <img
                                src={URL.createObjectURL(BackgroundcheckImageFront)}
                                alt="Profile"
                                style={{
                                  width: 300,
                                  height: 250,
                                  borderRadius: "19px",
                                }}
                              />
                            </div>
                          ) : ( 
                            <div>
                            <div
                              onClick={() =>
                                BackgroundcheckImageFrontInputRef.current.click()
                              }
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 30,
                                cursor: "pointer",
                              }}
                            >
                              <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3 }}>
                                <FileUploadIcon
                                  sx={{
                                    fontSize: 100,
                                    marginBottom: 3,
                                    color: colors.Header_Text_back,
                                  }}
                                />
                                {<Typography sx={{ textAlign: "center" }} fontFamily={FONT_FAMILY}>Background check Image</Typography>}
                              </Avatar>
                            </div>
                            <div style={{ marginTop: 20, textAlign: "center" }}>
                            <Typography sx={{ fontFamily: FONT_FAMILY }}>Background check Expiry Date</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> 
                            <input
                              type="date" 
                              onChange={BackgroundcheckExpiryDateChange}
                              value={BackgroundcheckExpiryDate}
                              style={{
                                marginTop: 10,
                                padding: 5,
                                borderRadius: "5px",
                                border: `1px solid ${colors.TABLE_BORDER}`,
                              }}
                            /> 
                          </div>
                          </div>
                            </div>
                          )}
                          <input
                            onChange={(event) => BackgroundcheckImageFrontChange(event)}
                            multiple={false}
                            ref={BackgroundcheckImageFrontInputRef}
                            type="file"
                            hidden
                          />
                        </>
                        {/* Safety line inspection image */}
                        <>
                          {SafetylineinspectionImageFront ? (
                            <div
                              onClick={() =>
                                SafetylineinspectionImageFrontInputRef.current.click()
                              }
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 10,
                              }}
                            >
                              <img
                                src={URL.createObjectURL(SafetylineinspectionImageFront)}
                                alt="Profile"
                                style={{
                                  width: 300,
                                  height: 250,
                                  borderRadius: "19px",
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                            <div
                              onClick={() =>
                                SafetylineinspectionImageFrontInputRef.current.click()
                              }
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 30,
                                cursor: "pointer",
                              }}
                            >
                              <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3 }}>
                                <FileUploadIcon
                                  sx={{
                                    fontSize: 100,
                                    marginBottom: 3,
                                    color: colors.Header_Text_back,
                                  }}
                                />
                                {<Typography sx={{ textAlign: "center" }} fontFamily={FONT_FAMILY}>Safety line Inspection Image</Typography>}
                              </Avatar>
                            </div>
                            <div style={{ marginTop: 20, textAlign: "center" }}>
                            <Typography sx={{ fontFamily: FONT_FAMILY }}>Safety line Inspection Expiry Date</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> 
                            <input
                              type="date"
                              onChange={SafetylineinspectionExpiryDateChange}
                              value={SafetylineinspectionExpiryDate}
                              style={{
                                marginTop: 10,
                                padding: 5,
                                borderRadius: "5px",
                                border: `1px solid ${colors.TABLE_BORDER}`,
                              }}
                            /> 
                          </div>
                          </div>
                            </div>
                          )}
                          <input
                            onChange={(event) => SafetylineinspectionImageFrontChange(event)}
                            multiple={false}
                            ref={SafetylineinspectionImageFrontInputRef}
                            type="file"
                            hidden
                          />
                        </>

                      </>
                    ) : null}
                  </>
                
              
              
              ) : (
                  



                  <Grid container spacing={10}> 
                    {data?.usertype === "driver" ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={4}
                          xl={4}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: 15,
                          }}
                        >
                          <Grid
                            item
                            style={{
                              width: "100%",
                            }}
                          >
                            <Card className={classes.card}>
                              <Typography
                                style={{
                                  textAlign: "center",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  fontFamily: FONT_FAMILY
                                }}
                              >
                                Car Insurance
                              </Typography>
                            </Card>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {data.carInsuranceImage ? (
                              <div>
                              <div onClick={() => setEditable(true)}>
                                <Avatar
                                  alt="carInsurance Image"
                                  src={data.carInsuranceImage}
                                  sx={{
                                    width: 300,
                                    height: 250,
                                    borderRadius: "19px",
                                    cursor: "pointer",
                                  }}
                                  variant="square"
                                />
                              </div>
                            <div style={{ marginTop: 20, textAlign: "center" }}>
                              <Typography sx={{ fontFamily: FONT_FAMILY }}>Car Insurance Expiry Date</Typography>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography sx={{ fontFamily: FONT_FAMILY }}>
                                {carInsuranceExpiryDate ? new Date(carInsuranceExpiryDate).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'}) : 'Not Set'}
                              </Typography> 
                              </div>
                            </div>
                            </div>
                            ) : ( 
                              <div onClick={() => setEditable(true)}>
                                <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3, cursor: "pointer", fontFamily: FONT_FAMILY, textAlign: "center" }}>
                                  Car Insurance
                                </Avatar>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    ) : null}

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={data.usertype === "customer" ? 6 : 4}
                      xl={data.usertype === "customer" ? 6 : 4}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 15,
                      }}
                    >
                      <Grid item style={{ width: "100%" }}>
                        <Card className={classes.card}>
                          <Typography
                            style={{
                              textAlign: "center",
                              fontSize: 16,
                              fontWeight: "bold",
                              fontFamily: FONT_FAMILY
                            }}
                          >
                            {t("driving_license_font")}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {data.licenseImage ? (
                          <div>
                          <div onClick={() => setEditable(true)}>
                            <Avatar
                              alt="license Image"
                              src={data.licenseImage}
                              sx={{
                                width: 300,
                                height: 250,
                                borderRadius: "19px",
                                cursor: "pointer",
                              }}
                              variant="square"
                            />
                          </div>
                          <div style={{ marginTop: 20, textAlign: "center" }}>
                            <Typography sx={{ fontFamily: FONT_FAMILY }}>Driving license Expiry Date </Typography>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> 
                              <Typography sx={{ fontFamily: FONT_FAMILY }}>
                                {DrivingLicenseExpiryDate ? new Date(DrivingLicenseExpiryDate).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'}) : 'Not Set'}
                              </Typography> 
                              </div>
                          </div>
                           
                            </div>
                        ) : ( 
                          <div onClick={() => setEditable(true)}>
                            <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3, cursor: "pointer", fontFamily: FONT_FAMILY, textAlign: "center" }}>
                              {t("driving_license_font")}
                            </Avatar>
                          </div>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      xl={4}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 15,
                      }}
                    >
                      <Grid item style={{ width: "100%" }}>
                        <Card className={classes.card}>
                          <Typography
                            style={{
                              textAlign: "center",
                              fontSize: 16,
                              fontWeight: "bold",
                              fontFamily: FONT_FAMILY,
                            }}
                          >
                            {t("driving_license_back")}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {data.licenseImageBack ? (
                          <div>
                          <div onClick={() => setEditable(true)}>
                            <Avatar
                              alt="license Image Back"
                              src={data.licenseImageBack}
                              sx={{
                                width: 300,
                                height: 250,
                                borderRadius: "19px",
                                cursor: "pointer",
                              }}
                              variant="square"
                            />
                          </div>
                          <div style={{
                                marginTop: 72,
                                padding: 5, 
                              }}>

                          </div>
                          </div>
                        ) : (
                          <div onClick={() => setEditable(true)}>
                            <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3, cursor: "pointer", fontFamily: FONT_FAMILY, textAlign: "center" }}>
                              {t("driving_license_back")}
                            </Avatar>
                          </div>
                        )}
                      </Grid>
                    </Grid>

                    {data?.usertype === "driver" ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={4}
                          xl={4}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: 15,
                          }}
                        >
                          <Grid item style={{ width: "100%" }}>
                            <Card className={classes.card}>
                              <Typography
                                style={{
                                  textAlign: "center",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  fontFamily: FONT_FAMILY
                                }}
                              >
                                Registration Image
                              </Typography>
                            </Card>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {data.RegistrationImage ? (
                              <div>
                              <div onClick={() => setEditable(true)}>
                                <Avatar
                                  alt="Registration Image"
                                  src={data.RegistrationImage}
                                  sx={{
                                    width: 300,
                                    height: 250,
                                    borderRadius: "19px",
                                    cursor: "pointer",
                                  }}
                                  variant="square"
                                />
                              </div> 
                              
                              <div style={{ marginTop: 20, textAlign: "center" }}>
                                <Typography sx={{ fontFamily: FONT_FAMILY }}>Registration Expiry Date</Typography>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography sx={{ fontFamily: FONT_FAMILY }}>
                                  {RegistrationExpiryDate ? new Date(RegistrationExpiryDate).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'}) : 'Not Set'}
                                </Typography> 
                                </div>
                              </div>
                            </div>
                            ) : ( 
                              <div onClick={() => setEditable(true)}>
                                <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3, cursor: "pointer", fontFamily: FONT_FAMILY, textAlign: "center" }}>
                                  Registration Image
                                </Avatar>
                              </div> 
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={4}
                          xl={4}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: 15,
                          }}
                        >
                          <Grid item style={{ width: "100%" }}>
                            <Card className={classes.card}>
                              <Typography
                                style={{
                                  textAlign: "center",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  fontFamily: FONT_FAMILY,
                                }}
                              >
                                Background check Image
                              </Typography>
                            </Card>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {data.BackgroundcheckImage ? (
                              <div>
                              <div onClick={() => setEditable(true)}>
                                <Avatar
                                  alt="Backgroundcheck Image"
                                  src={data.BackgroundcheckImage}
                                  sx={{
                                    width: 300,
                                    height: 250,
                                    borderRadius: "19px",
                                    cursor: "pointer",
                                  }}
                                  variant="square"
                                />
                              </div>
                          <div style={{ marginTop: 20, textAlign: "center" }}>
                            <Typography sx={{ fontFamily: FONT_FAMILY }}>Background check Expiry Date</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  
                            <Typography sx={{ fontFamily: FONT_FAMILY }}>
                                {BackgroundcheckExpiryDate ? new Date(BackgroundcheckExpiryDate).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'}) : 'Not Set'}
                              </Typography> 
                          </div>
                          </div>
                            </div>
                            ) : (
                              <div onClick={() => setEditable(true)}>
                                <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3, cursor: "pointer", fontFamily: FONT_FAMILY, textAlign: "center" }}>
                                  Background check Image
                                </Avatar>
                              </div>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={4}
                          xl={4}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: 15,
                          }}
                        >
                          <Grid item style={{ width: "100%" }}>
                            <Card className={classes.card}>
                              <Typography
                                style={{
                                  textAlign: "center",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  fontFamily: FONT_FAMILY,
                                }}
                              >
                                Safetyline Inspection Image
                              </Typography>
                            </Card>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >

                            {data.SafetylineinspectionImage ? (
                              <div>
                              <div onClick={() => setEditable(true)}>
                                <Avatar
                                  alt="Safetyline Inspection Image"
                                  src={data.SafetylineinspectionImage}
                                  sx={{
                                    width: 300,
                                    height: 250,
                                    borderRadius: "19px",
                                    cursor: "pointer",
                                  }}
                                  variant="square"
                                />
                              </div>
                          <div style={{ marginTop: 20, textAlign: "center" }}>
                            <Typography sx={{ fontFamily: FONT_FAMILY }}>Safety line Inspection Expiry Date</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> 
                            <Typography sx={{ fontFamily: FONT_FAMILY }}>
                                {SafetylineinspectionExpiryDate ? new Date(SafetylineinspectionExpiryDate).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'}) : 'Not Set'}
                              </Typography> 
                          </div>
                          </div>
                          </div>
                            ) : ( 
                              <div onClick={() => setEditable(true)}>
                                <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3, cursor: "pointer", fontFamily: FONT_FAMILY, textAlign: "center" }}>
                                  Safetyline Inspection Image
                                </Avatar>
                              </div> 
                            )}
                          </Grid>

                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item>
              {!editable ? (
                <Button className={classes.buttonStyle}
                  style={{
                    backgroundColor: MAIN_COLOR,
                    width: "50%",
                  }}
                  variant="contained"
                  onClick={() => setEditable(true)}
                  sx={{
                    cursor: "pointer",
                    borderColor: colors.CARD_DETAIL,
                  }}
                >
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: 16,
                      color: colors.WHITE,
                      fontWeight: "bold",
                      fontFamily: FONT_FAMILY
                    }}
                  >
                    {t("edit")}
                  </Typography>
                </Button>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: isRTL === "rtl" ? "row-reverse" : "row",
                    gap: 10,
                  }}
                >
                  <Button className={classes.buttonStyle}
                    style={{
                      backgroundColor: colors.GREEN,
                      width: "40%",
                    }}
                    variant="contained"
                    onClick={() => {
                      handleSaveUser();
                      handleUpdate();
                    }}
                  >
                    <Typography
                      style={{
                        color: colors.WHITE,
                        textAlign: "center",
                        fontSize: 16,
                        fontWeight: "bold",
                        fontFamily: FONT_FAMILY
                      }}
                    >
                      {t("save")}
                    </Typography>
                  </Button>
                  <Button className={classes.buttonStyle}
                    style={{
                      backgroundColor: colors.RED,
                      width: "40%",
                    }}
                    variant="contained"
                    onClick={handleCancel}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        fontWeight: "bold",
                        color: colors.WHITE,
                        fontFamily: FONT_FAMILY
                      }}
                    >
                      {t("cancel")}
                    </Typography>
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </Card>
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
          {commonAlert.msg}
        </AlertDialog>
      </div>
    </>
  );
}

export default UserDocuments;
