import { Autocomplete, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { api } from "common";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax";
import { colors } from "components/Theme/WebTheme.js";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FONT_FAMILY } from "../common/sharedFunctions";
import AlertDialog from '../components/AlertDialog';
import styles from '../styles/staticPages.js';

const dashboardRoutes = [];
 
const useStyles = makeStyles(styles);
export default function RatesCalculator(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;
 
  const [pincode1, setZipcode] = useState(null);
  const [filteredFares, setFilteredFares] = useState([]);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const isMidScreen = useMediaQuery('(max-width:800px)');
  const [data, setData] = useState([]);
  const ratelistdata = useSelector((state) => state.ratelist);
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const OhareFare = '60666';
  const MidwayFare = '60629';

  useEffect(() => {
      dispatch(api.fetchRate());
  }, [dispatch]);

  useEffect(() => {
      if (ratelistdata.rate) {
          const uniquePincodes = Array.from(new Set(ratelistdata.rate.map(item => item.pincode1.trim())));
          setData(uniquePincodes);
      } else {
          setData([]);
      }
  }, [ratelistdata.rate]);
  
  useEffect(() => {
    if (pincode1) {
        handleAddBalance();
    }
}, [pincode1]);

  useEffect(() => {
    // Debug: Log all localStorage keys
    const allKeys = Object.keys(localStorage);
    // console.log('All localStorage keys:', allKeys);
    
    const userStr = localStorage.getItem('userDetails');
    // console.log('User String:', userStr);
    
    // Try getting the data directly
    const firebaseUser = localStorage.getItem('firebase:authUser:AIzaSyBqDLR2AORpOqHYJVD4kFsITDJzhkQmxG0:[DEFAULT]');
    // console.log('Firebase User Data:', firebaseUser);
    
    if (firebaseUser) {
      try {
        const userData = JSON.parse(firebaseUser);
        // console.log('Parsed User Data:', userData);
        
        const email = userData?.email;
        const accessToken = userData?.stsTokenManager?.accessToken;
        
        // console.log('Email:', email);
        // console.log('Access Token:', accessToken);
        
        setIsAuthenticated(!!accessToken);
        // console.log('Is Authenticated:', !!accessToken);
      } catch (error) {
        console.error('Error parsing user data:', error);
        setIsAuthenticated(false);
      }
    } else {
      console.log('No user data found in localStorage');
      setIsAuthenticated(false);
    }
  }, []);

  const handleAddBalance = () => {
      if (pincode1) {
          const filteredFares = ratelistdata.rate.filter(rate => rate.pincode1.trim() === pincode1.trim());
          setFilteredFares(filteredFares);
          if (filteredFares.length > 0) {
              // setCommonAlert({ open: true, msg: t('success') });
          } else {
              setCommonAlert({ open: true, msg: 'Service Unavailabe and Contact Us' });
          }
      } else {
          setFilteredFares([]);
        //   setCommonAlert({ open: true, msg: t('no_details_error') });
      }
  }

  const handleCommonAlertClose = (e) => {
      e.preventDefault();
      setCommonAlert({ open: false, msg: '' })
  };

  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
        <div className={classes.container}>
            <br/>
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <Grid item>
                    <Grid item xs={12}>
                        <Typography className={classes.title} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                            {t('Rate Calculator')}
                        </Typography>
                    </Grid>
                    <div className={classes.container1}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Autocomplete
                                    options={isAuthenticated ? data : []}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder={isAuthenticated ? t('Enter your Zipcode') : t('Please login to see suggestions')}
                                            className={classes.items}
                                        />
                                    }
                                    value={pincode1}
                                    onChange={(event, newValue) => {
                                        setZipcode(newValue);
                                    }}
                                    freeSolo
                                    noOptionsText={isAuthenticated ? t('No matching zipcodes') : t('Please login to see suggestions')}
                                />
                            </Grid>
                          
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                                {filteredFares.length > 0 && (
                                    <Grid container spacing={2} className={classes.farecardstyle} style={{ marginTop: '10px', background: '#06113c' }}>
                                        {filteredFares.map((rate, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Typography style={{ color: colors.WHITE, fontFamily: FONT_FAMILY }}>
                                                           
                                                        {rate.pincode1} to {rate.pincode2 === OhareFare ? "O'Hare" : rate.pincode2 === MidwayFare ? "Midway" : rate.pincode2}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textAlign: 'right', paddingRight: '20px' }}>
                                                        <Typography style={{ color: colors.WHITE, fontFamily: FONT_FAMILY }}>
                                                            :  ${rate.fare}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </Grid>

                        </Grid>
                    </div>
                </Grid>
            </Grid>
            <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>

            <br/>
        </div>
        </div>

      <Footer />
    </div>
  );
}
