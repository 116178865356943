import 'bootstrap/dist/css/bootstrap.min.css';
import { FirebaseProvider, store } from "common";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import Complain from "views/Complain.js";
import Completetrip from "views/Completetrips";
import CompletetripsDetail from "views/CompletetripsDetails";
import CustomerDetails from "views/CustomerDetails.js";
import Neworder from "views/Neworders";
import AboutUs from "./views/AboutUs";
import AddBookings from './views/AddBookings';
import AddMoney from "./views/AddMoney";
import AllReports from "./views/AllReports";
import AuthLoading from './views/AuthLoading';
import BookingHistory from './views/BookingHistory';
import CarTypes from './views/CarTypes';
import Dashboard from './views/Dashboard';
import LandingPage from "./views/LandingPage.js";
import LoginPage from "./views/LoginPage.js";
import Managebooking from './views/Managebooking';
import MyProfile from './views/MyProfile';
import Notifications from './views/Notifications';
import PrivacyPolicy from "./views/PrivacyPolicy.js";
import Promos from './views/Promos';
import ProtectedRoute from './views/ProtectedRoute';
import Rates from './views/Rates';
import RatesCalculator from "./views/RatesCalculator";
import Settings from './views/Settings.js';
import Todayorder from './views/Todayorders';
import Users from './views/Users';
import Withdraws from './views/Withdraws';
// import { FirebaseConfig } from '../config/FireBaseConfig.js';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { useJsApiLoader } from '@react-google-maps/api';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { webClientId } from "config/ClientIds.js";
import i18n from "i18next";
import { HelmetProvider } from "react-helmet-async";
import { initReactI18next } from "react-i18next";
import AddNotifications from "views/AddNotifications.js";
import BookingDetails from "views/BookingDetails.js";
import DriverDetails from "views/DriverDetails.js";
import EditCar from "views/EditCar.js";
import EditCarType from "views/EditCarType.js";
import EditFare from "views/EditFare.js";
import EditPromo from "views/EditPromo.js";
import EditRate from "views/EditRates.js";
import EditUser from "views/EditUser.js";
import Error from "views/Error.js";
import ManageBookingDetails from "views/manageBookingDetails";
import NewordersDetails from "views/NewordersDetails";
import PaymentSettings from "views/PaymentSettings.js";
import TermCondition from "views/TermCondition.js";
import TodayordersDetails from "views/TodayordersDetails";
import UserDocuments from "views/UserDocuments.js";
import { FirebaseConfig } from './config/FireBaseConfig';
import { GoogleMapApiConfig } from './config/GoogleMapApiConfig';
import CarsList from "./views/CarsList";
import ContactUs from "./views/ContactUs";
import PointToPoint from './views/PointToPoint';
import Sos from './views/Sos';
import UserWallet from "./views/UserWallet";
const libraries = ['geometry','drawing','places'];

i18n
.use(initReactI18next) 
.init({
    resources: {},
    fallbackLng: "en",
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
        escapeValue: false
    }
});

function App() {

  useJsApiLoader({
    id: 'google-map',
    googleMapsApiKey: GoogleMapApiConfig + "&loading=async",
    libraries
  })

  const theme = createTheme()

  return (
    <HelmetProvider>
    <Provider store={store}>
      <FirebaseProvider config={FirebaseConfig}>
        <GoogleOAuthProvider clientId={webClientId}>
        <ThemeProvider theme={theme}>
          <AuthLoading>
           <BrowserRouter>
              <Routes>
                <Route path="/dashboard" element={<ProtectedRoute permit={"admin"}><Dashboard /></ProtectedRoute>}/>
                <Route path="/p2p" element={<ProtectedRoute permit={"admin"}><PointToPoint /></ProtectedRoute>}/>
                <Route path="/faretype/editfaretype" element={<ProtectedRoute permit={"admin"} ><EditFare/></ProtectedRoute>} />
                <Route path="/faretype/editfaretype/:id" element={<ProtectedRoute permit={"admin"}><EditFare/></ProtectedRoute>}/>
                <Route path="/bookings" element={<ProtectedRoute permit={"customer,admin,driver,fleetadmin"}><BookingHistory /></ProtectedRoute>}/>
                <Route path="/todayorder" element={<ProtectedRoute permit={"admin,fleetadmin"}><Todayorder /></ProtectedRoute>}/>
                <Route path="/todayorder/todayordersdetails/:id" element={<ProtectedRoute permit={"admin,fleetadmin"}><TodayordersDetails /></ProtectedRoute>}/>
                <Route path="/neworder" element={<ProtectedRoute permit={"admin,fleetadmin"}><Neworder /></ProtectedRoute>}/>
                <Route path="/neworder/newordersdetails/:id" element={<ProtectedRoute permit={"admin,fleetadmin"}><NewordersDetails /></ProtectedRoute>}/>
                <Route path="/completetrips" element={<ProtectedRoute permit={"admin,fleetadmin"}><Completetrip/></ProtectedRoute>}/>
                <Route path="/completetrips/completetripsdetails/:id" element={<ProtectedRoute permit={"admin,fleetadmin"}><CompletetripsDetail /></ProtectedRoute>}/>
                <Route path="/Managebooking" element={<ProtectedRoute permit={"customer,admin,fleetadmin"}><Managebooking /></ProtectedRoute>}/>
                <Route path="/bookings/bookingdetails/:id" element={<ProtectedRoute permit={"customer,admin,driver,fleetadmin"}><BookingDetails /></ProtectedRoute>}/>
                <Route path="/bookings/managebookingdetails/:id" element={<ProtectedRoute permit={"customer,admin"}><ManageBookingDetails /></ProtectedRoute>}/>
                <Route path="/profile" element={<ProtectedRoute permit={"customer,admin,driver,fleetadmin"}><MyProfile /></ProtectedRoute>}/>
                <Route path="/cartypes" element={<ProtectedRoute permit={"admin"}><CarTypes /></ProtectedRoute>}/>
                <Route path="/cars" element={<ProtectedRoute permit={"admin,driver"}><CarsList /></ProtectedRoute>}/>
                <Route path="/addbookings" element={<ProtectedRoute permit={"admin,fleetadmin,customer"}><AddBookings /></ProtectedRoute>}/>
                <Route path="/Rates" element={<ProtectedRoute permit={"admin"}><Rates /></ProtectedRoute>}/>
                <Route path="/Rates/editRatestype" element={<ProtectedRoute permit={"admin"} ><EditRate/></ProtectedRoute>} />
                <Route path="/Rates/editRatestype/:id" element={<ProtectedRoute permit={"admin"}><EditRate/></ProtectedRoute>}/>
                <Route path="/promos" element={<ProtectedRoute permit={"admin"}><Promos /></ProtectedRoute>}/>
                <Route path="/users/:id" element={<ProtectedRoute permit={"admin"}><Users /></ProtectedRoute>}/>
                <Route path="/users/customerdetails/:id" element={<ProtectedRoute permit={"admin"}><CustomerDetails/></ProtectedRoute>}/>
                <Route path="/users/driverdetails/:id" element={<ProtectedRoute permit={"admin"}><DriverDetails/></ProtectedRoute>}/>
                <Route path="/users/userdocuments/:rId/:id" element={<ProtectedRoute permit={"admin"}><UserDocuments/></ProtectedRoute>} />  
                <Route path="/notifications" element={<ProtectedRoute permit={"admin"}><Notifications /></ProtectedRoute>}/>
                <Route path="/addtowallet" element={<ProtectedRoute permit={"admin"}><AddMoney /></ProtectedRoute>}/>
                <Route path="/userwallet" element={<ProtectedRoute permit={"customer,driver,fleetadmin"}><UserWallet /></ProtectedRoute>}/>
                <Route path="/withdraws" element={<ProtectedRoute permit={"admin"}><Withdraws /></ProtectedRoute>}/>
                <Route path="/sos" element={<ProtectedRoute permit={"admin"}><Sos /></ProtectedRoute>}/>
                <Route path="/complain" element={<ProtectedRoute permit={"admin"}><Complain /></ProtectedRoute>}/>
                <Route path="/allreports" element={<ProtectedRoute permit={"admin"}><AllReports /></ProtectedRoute>}/>
                <Route path="/settings" element={<ProtectedRoute permit={"admin"}><Settings /></ProtectedRoute>}/>
                <Route path="/paymentsettings" element={<ProtectedRoute permit={"admin"}><PaymentSettings /></ProtectedRoute>}/>
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/RatesCalculator" element={<RatesCalculator/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/term-condition" element={<TermCondition />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/users/edituser/:usertype" element={<ProtectedRoute permit={"admin"}><EditUser/></ProtectedRoute>}/>
                <Route path="/users/edituser/:usertype/:id" element={<ProtectedRoute permit={"admin"}><EditUser/></ProtectedRoute>}/>
                <Route path="/notifications/addnotifications" element={<ProtectedRoute permit={"admin"}><AddNotifications/></ProtectedRoute>}/>
                <Route path="/cars/editcar" element={<ProtectedRoute permit={"admin,driver"}><EditCar/></ProtectedRoute>}/>
                <Route path="/cars/editcar/:id" element={<ProtectedRoute permit={"admin,driver"}><EditCar/></ProtectedRoute>}/>
                <Route path="/promos/editpromo" element={<ProtectedRoute permit={"admin"}><EditPromo/></ProtectedRoute>}/>
                <Route path="/promos/editpromo/:id" element={<ProtectedRoute permit={"admin"}><EditPromo/></ProtectedRoute>}/>
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/cartypes/editcartype" element={<ProtectedRoute permit={"admin,driver"} ><EditCarType/></ProtectedRoute>} />
                <Route path="/cartypes/editcartype/:id" element={<ProtectedRoute permit={"admin,driver"}><EditCarType/></ProtectedRoute>}/>
                <Route path="/*" element={<Error/>} />
                <Route path="/" element={<LandingPage />} />
              </Routes>
            </BrowserRouter>
          </AuthLoading>
        </ThemeProvider>
        </GoogleOAuthProvider>
      </FirebaseProvider>
    </Provider>
    </HelmetProvider>
  );
}

export default App;