import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { colors } from "components/Theme/WebTheme.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function TermCondition(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { ...rest } = props;
  
  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      
      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
        <h2 style={{ color: '#D8232A' }}>Taxi2fly Terms & Conditions</h2>
        <p>
          Thank you for using our Services. These are our relevant general terms and conditions
          (Terms and Conditions) that apply to your visit to and your use of our Website, Application,
          Call Centre and all other related and ancillary services.
        </p>

        <p>
          Taxi2fly is a Taxi Booking and Payment Service enabling you to book, and pay for, a taxi cab
          through the use of an application supplied by us and downloaded and installed by you on your
          single mobile device (smartphone) (Application), via our Call Centre by calling{' '}
          <b>+16309430600</b> (Call Centre) or via our website at{' '}
          <a href="http://www.taxi2fly.net" target="_blank" rel="noopener noreferrer">
            www.taxi2fly.net
          </a>{' '}
          (Website).
        </p>

        <p>
          Taxi2fly connects you to taxi drivers so that taxi drivers may pick you, or your parcels, up
          and take you, or your parcels, where you need to go. All services provided to you by means of
          use of the Application, Call Centre and/or Website, as well as all other related and
          ancillary services such as parcel delivery, text message confirmations and "contact your
          driver" are collectively referred to as the Service(s).
        </p>

        <p>
          Taxi2fly does not provide transportation services. Taxi2fly facilitates the formation and
          administration of travel arrangements between you and taxi drivers. By using our Services,
          you are acknowledging that taxi2fly is not providing you with transportation services and
          agreeing that taxi2fly has no responsibility or liability for any transportation services or
          parcel delivery services provided to you by taxi drivers.
        </p>

        <p>
          Further information about us can be found at{' '}
          <a href="http://www.taxi2fly.net" target="_blank" rel="noopener noreferrer">
            www.taxi2fly.net
          </a>
        </p>

        <h3 style={{ color: '#D8232A' }}>Eligibility</h3>
        <p>
          You must be at least 18 years old to use our services. By using our services, you represent
          and warrant that you are at least 18 years old.
        </p>

        <h3 style={{ color: '#D8232A' }}>General</h3>
        <p>
          By registering on our website and/or Application, and on each occasion that you choose to
          use our Services, you consent to these Terms and Conditions. Neither the registration nor
          the use of the complete spectrum of Services shall be possible without explicit consent to
          these Terms and Conditions.
        </p>

        <p>
          We reserve the right to amend these Terms and Conditions at any time. Following such
          amendment, the continued use of our Services by you is subject to your consent to such
          amended terms and conditions. Your continued use of the Services following posting of any
          changes to this agreement constitutes acceptance of those changes.. It is your responsibility to check this agreement periodically for changes.
Taxi2fly may also, in the future offer news services and/or features through the Services. Such new
features and/or services shall be subject to these Terms and Conditions.

        </p>


<p>You will be notified or any changes to these Terms and Conditions via email or the Application.
</p>

<p>By using our Services, you are entering into an agreement with us to be bound by these Terms and
Conditions. In order to be able to first use our Services, you first need to sign up with us. When signing
up, you are obliged to provide us with your personal information. Upon successful completion of your
signing up with us, we will provide you with a personal account, accessible for you with a password of
your choice. You may also be required to provide us with your credit card or Cab charge account details</p>


<h3 style={{ color: '#D8232A' }}>Payment</h3>
<p>The use of our Services is free of charge. We reserve the right to introduce a fee for the use of our
Services. If we decide to introduce such a fee, we shall inform you accordingly and allow you to either
continue or terminate using our Services.
</p>

<p>In some cases, we may authorize: (a) a temporary charge on your credit card (or whatever payment
method you use) for any amount up to $1 to verify that your card is open and in good standing. This
charge will appear as ‘pending’ and will be automatically removed after verification; (b) a temporary
charge on your credit card (or whatever payment method you use) for any amount up to the estimated
amount of the fare to verify that your card is open and in good standing. If you have completed the trip
then the temporary charge will be used to pay the fare but if you cancel the trip then any pre-authorized
amount will be refunded to you.
</p>

<p>If you choose to pay via the Website, Application or Call Centre, we shall charge you for the taxi services
provided to you by the taxi drivers on behalf of the taxi drivers. You agree that you will pay for all taxi
services, and that we may charge your credit card or Cab charge account as provided by you for the taxi
services (including any taxes and late fees, as applicable) that may be accrued by or in connection with
your account. You are responsible for the timely payment of all fees and for providing us with a valid
credit card or Cab charge account for payment of all fees at all times. Any payment made is
non-refundable.</p>

<p>If we use a third-party payment processor (the “Payment Processor “) to link your credit card account to
the Service, this paragraph will apply. The processing of payments or credits, as applicable, in connection
with your use of the Service will be subject to the terms, conditions and privacy policies of the Payment
Processor and your credit card issuer in addition to these Terms and Conditions. We are not responsible
for any errors by the Payment Processor. In connection with your use of the Services, we will obtain
certain transaction details, which we will use solely in accordance with our Privacy Policy.
</p>

<h3 style={{ color: '#D8232A' }}>Conditions of Carriage for Parcel Delivery</h3>

<p>You understand that the use of our Services may attract data and/or communication carriage charges.</p>

<p>We are not responsible for any data or telephone charges incurred by you as a result of you using our
Services, including but not limited to mobile phone charges, text messages and internet.</p>


<h3 style={{ color: '#D8232A' }}>Links to Third Party Sites</h3>


<p>The Services may contain links to other Websites (“Linked Sites”). The Linked Sites are not under the
control of taxi2fly and taxi2fly is not responsible for the contents of any Linked Site, including without
limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Taxi2fly is not
responsible for webcasting or any other form of transmission received from any Linked Site. Taxi2fly is
providing these links to you only as a convenience, and the inclusion of any link does not imply
endorsement by taxi2fly of the site or any association with its operators.</p>

<p>Please note that these other websites may send their own cookies to users, collect data or solicit
personal information, and you are therefore advised to check the terms of use or privacy policies on
those websites prior to using them.
</p>

<h3 style={{ color: '#D8232A' }}>Warranty or Guarantee</h3>

<p>Taxi2fly presents the information on this website as a convenience. We pride ourselves on prompt,
efficient and courteous service and do all we can to anticipate and avoid problems.
</p>

<p>At the same time, taxi (cab) service is affected by weather, road conditions, road construction,
mechanical failure, taxi availability and occasional misunderstandings as to directions and location. In
addition, time for processing passengers for baggage and security at airports varies widely depending on
security precaution levels, volume of passengers, airline and airport delays and other factors.
</p>

<p>Taxi2fly cannot guarantee connections with specific flights, trains and other scheduled appointments nor
be responsible for consequences, costs or damages for missed flights or appointments. Leave yourself
plenty of time to arrive at the airport for your flights when directing us as to pick-up times, and be aware
of airport arrival times as suggested by airlines. Pick-up times can be scheduled and we try to arrive on
time to the scheduled pick-up time but pick-up times arranged in advance by “reservations” are not and
never guaranteed.
</p>

<h3 style={{ color: '#D8232A' }}>No Unlawful or Prohibited Use</h3>

<p>You warrant that the information you provide to us is accurate and complete. We are entitled at all times
to verify the information that you have provided and to refuse the Service without providing reasons.
</p>

<p>You may only access the Services using authorized means. It is your responsibility to check to ensure you
download the correct Application for your device and/or use the correct web browser when accessing
the Website. We are not liable if you do not have a compatible mobile device or you download the
wrong version of the Application for your mobile device. We reserve the right to terminate any Service
should you be using the Service or an incompatible or unauthorized device.
</p>

<p>As a condition of your use of the Services, you warrant to us that you will not use the Services for any
purpose that is unlawful or prohibited by these Terms and Conditions and any notices provided to you by
us. You may not use the Services in any manner which could damage, disable, overburden, or impair the
Services or interfere with any other party’s use and enjoyment of the Services. You may not obtain or
attempt to obtain any materials or information through any means not intentionally made available or
provided for through the Services. You will only use the Services for your sole, personal use and will not
resell it to a third party. You will keep secure and confidential your account password or any
identification we provide you which allows access to any Service</p>

<p>Taxi2fly reserves the rights to immediately terminate any Service should you not comply with any of the
above rules.
</p>


<h3 style={{ color: '#D8232A' }}>Term and termination</h3>
<ul style={{ listStyleType:'none' }}>

  <li>The agreement between us and you last</li>
  <li>For as long as we are providing a Service to you; and/or</li>
  <li>For as long as you have an account open with us.  </li>
  <li>You are entitled to terminate the agreement at all times by permanent deletion of the Application
  installed on your smart phone, by closing down your user account or by ceasing to use our Services</li>
  <li>Taxi2fly reserves the right, in its sole discretion, to terminate your access to the Services or any portion
  thereof at any time, without notice if you</li>
  <li>Violate or breach any term of these Terms and Conditions, or</li>
  <li>In our opinion, misuse the Service.  </li>
  <li>Materials Provided to taxi2fly or posted on the Website  </li>
 
</ul>

<p>Taxi2fly does not claim ownership of the materials you provide to taxi2fly (including feedback and
suggestions) or post, upload, input or submit to any Website or its associated services (collectively
Submissions). However, by posting, uploading, inputting, providing or submitting your Submission you
are granting taxi2fly, its affiliated companies and necessary sub-licensees permission to use your
Submission in connection with the operation of their Internet businesses including, without limitation,
the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and
reformat your Submission; and to publish your name in connection with your Submission</p>

<p>No compensation will be paid with respect to the use of your Submission, as provided herein. Taxi2fly is
under no obligation to post or use any Submission you may provide and may remove any Submission at
any time in taxi2fly sole discretion.
</p>

<p>By posting, uploading, inputting, providing or submitting your Submission you warrant and represent
that you own or otherwise control all of the rights to your Submission as described in this section
including, without limitation, all the rights necessary for you to provide, post, upload, input or submit
the Submissions.
</p>

<h3 style={{ color: '#D8232A' }}>Information for Personal, Non-Commercial Use Only</h3>
<p>You agree that information provided to you by taxi2fly in connection with the Services is for personal
use only and may not be sold, redistributed or used for any commercial purpose (this includes but is not
limited to the use of Advertiser contact details for unsolicited commercial correspondence). You may
download material from our Services for your personal, non-commercial use only, provided you keep
intact all copyright and other proprietary notices. You may not modify, copy, reproduce, republish,
upload, post, transmit or distribute in any way any material from our Services including code and
software. You must not use our Services for any purpose that is unlawful or prohibited by these terms of
use.</p>

<p>You may not use data mining, robots, screen scraping, or similar data gathering and extraction tools on
our Services for establishing, maintaining, advancing or reproducing information contained on our
Services on your own website, application or in any other publication, except with our prior written
consent</p>


<h3 style={{ color: '#D8232A' }}>Privacy</h3>
<p>You agree that taxi2fly may collect and use, personal information, technical data and related information
– including but not limited to technical information about your device, system and application software
and peripherals – that is gathered periodically to facilitate the provisions of software updates, product
support, and other services to you (if any) related to the Services and to administer those Services. We
may also use information to develop new products and services, attend to your enquiries, seek feedback,
or tell you about other products or services offered by taxi2fly and its related companies. We may base
this information as long as it is in a form that does not personally identify you, to improve our products
or to provide services or technologies to you.
</p>

<p>Taxi2fly complete privacy policy can be found at: <a href="https://www.taxi2fly.net/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.taxi2fly.net/privacy-policy</a></p>


<h3 style={{ color: '#D8232A' }}>Liability</h3>

<p>The information, software, products, and services included in or available through the Services may
include inaccuracies or typographical errors. Changes are periodically added to the information. Taxi2fly
and/or its suppliers may make improvements and/or changes in the Services at any time. Advice received
via the Services should not be relied upon for personal, medical, legal or financial decisions and you
should consult an appropriate professional for specific advice tailored to your situation</p>

<p>Taxi2fly and/or its suppliers make no representations about the suitability, reliability, availability,
timeliness, and accuracy of the information, software, products, services and related graphics contained
on the Services for any purpose. To the maximum extent permitted by applicable law, all such
information, software, products, services and related graphics are provided “as is” without warranty or
condition of any kind. Taxi2fly and/or its suppliers hereby disclaim all warranties and conditions with
regard to this information, software, products, services and related graphics, including all implied
warranties or conditions of merchantability, fitness for a particular purpose, title and non-infringement.
</p>

<p>To the maximum extent permitted by applicable law, in no event shall taxi2fly and/or its suppliers be
liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages
whatsoever including, without limitation, damages for loss of use, injury, damage, destruction or loss to
goods or any other property, the mis-delivery or non-delivery of good, data or profits, arising out of or in
any way connected with the use or performance of the Services, with the delay or inability to use the
Services or related services, the provision of or failure to provide services, or for any information,
software, products, services and related graphics obtained through the Services, or otherwise arising out
of the use of the Services, whether based on contract, tort, negligence, strict liability or otherwise, even
if taxi2fly or any of its suppliers has been advised of the possibility of damages. Because some
states/jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental
damages, the above limitation may not apply to you. If you are dissatisfied with any portion of the
Service, or with any of these terms of use, your sole and exclusive remedy is to discontinue using the
Services.</p>

<h3 style={{ color: '#D8232A' }}>Indemnification</h3>

<p>By accepting these terms and conditions and using the Services, you agree to indemnify and hold
taxi2fly, its affiliates, its licensors, and each of their officers, directors, other users, employees, attorneys
and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses
(including lawyer’s fees and costs) arising out of or in connections with your use of the Services,
including but not limited to your violation of these Terms and Conditions</p>

<p>These Terms and Conditions were updated on 12/15/2024.</p>

<h3 style={{ color: '#D8232A' }}>Termination:</h3>

<p>We reserve the right to terminate a user’s access to our platform at any time, without notice, for any
reason</p>

      </div>
      
      <Footer/>
    </div>
  );
}
