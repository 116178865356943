import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DownloadIcon from "@mui/icons-material/Download"; // Import the Download icon
import { Avatar, Box, Button, Card, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import jsPDF from 'jspdf'; // Import jsPDF
import moment from "moment/min/moment-with-locales";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FONT_FAMILY, MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { colors } from "../components/Theme/WebTheme";

const useStyles = makeStyles((theme) => ({
    Download: { 
      backgroundColor: colors.WHITE,
    },
  card: {
    borderRadius: "10px",
    backgroundColor: colors.WHITE,
    minHeight: 100,
    marginTop: 5,
    marginBottom: 20,
    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
  },
  txt: {
    padding: 10,
    fontWeight: "bold",
    minHeight: 60,
    backgroundColor: SECONDORY_COLOR,
    color: colors.BLACK,
    boxShadow: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function CompletetripsDetail() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  console.log("data",data)
  const settings = useSelector((state) => state.settingsdata.settings);
  const classes = useStyles();
  const role = useSelector(state => state.auth.profile.usertype);

  useEffect(() => {
    if (bookinglistdata.bookings) {
      const booking = bookinglistdata.bookings.filter(
        (item) => item.id === id.toString()
      )[0];

      if (booking) {
        setData(booking);
        console.log("snxlnakx",setData(booking))
      } else {
        navigate("/404");
        setData([]);
      }
    }
  }, [bookinglistdata.bookings, id, navigate]);

  const handleExport = () => {
    const csvData = [
      ["Key", "Value"],
      ["Booking ID", data.id],
      ["Booking Reference", data.reference],
      ["Booking Status", t(data.status)],
      ["Booking Date", data?.bookingDate ? moment(data?.bookingDate).format("lll") : ""],
      ["Trip Start Date", data?.tripdate ? moment(data?.tripdate).format("lll") : ""],
      ["Trip Start Time", data?.trip_start_time],
      ["Trip End Time", data?.trip_end_time],
      ["Parcel Type", data.parcelTypeSelected ? data.parcelTypeSelected.description + " (" + data.parcelTypeSelected.amount + ")" : ""],
      ["Parcel Option", data.optionSelected ? data.optionSelected.description + " (" + data.optionSelected.amount + ")" : ""],
      ["Delivery Person", data?.deliveryPerson],
      ["Delivery Person Phone", data.deliveryPersonPhone ? (settings.AllowCriticalEditsAdmin ? data.deliveryPersonPhone : t("hidden_demo")) : ""],
      ["Pickup Image", data.pickup_image ? data.pickup_image : ""],
      ["Deliver Image", data.deliver_image ? data.deliver_image : ""],
      ["Trip Instructions", data?.tripInstructions],
      ["Cancellation Reason", data?.reason],
      ["OTP", data?.otp],
      ["Total Time", data?.total_trip_time],
      ["Distance", data.distance ? (settings.convert_to_mile ? data?.distance + t("mile") : data?.distance + " " + t("km")) : ""],
      ["Pickup Instructions", data?.pickUpInstructions],
      ["Delivery Instructions", data?.deliveryInstructions],
      ["Other Person", data?.otherPerson],
      ["Other Person Phone", data?.otherPersonPhone],
      ["Feedback", data?.feedback],
      ["Pickup Address", data?.pickupAddress],
      ["Drop Address", data?.dropAddress],
      ["Trip Cost", settings.swipe_symbol ? data?.trip_cost + " " + settings.symbol : settings.symbol + " " + data?.trip_cost],
      ["Tips", data?.tips ? (settings.swipe_symbol ? data?.tips + " " + settings.symbol : settings.symbol + " " + data?.tips) : ""],
      ["Customer Paid", data?.customer_paid ? (settings.swipe_symbol ? data?.customer_paid + " " + settings.symbol : settings.symbol + " " + data?.customer_paid) : ""],
      ["Discount Amount", settings.swipe_symbol ? data?.discount + " " + settings.symbol : settings.symbol + " " + data?.discount],
      // ["Driver Share", settings.swipe_symbol ? data?.driver_share + " " + settings.symbol : settings.symbol + " " + data?.driver_share],
      ["Fleet Admin Commission", data?.fleetCommission && parseFloat(data?.fleetCommission) > 0 ? (settings.swipe_symbol ? data?.fleetCommission + " " + settings.symbol : settings.symbol + " " + data?.fleetCommission) : ""],
      ["Van Fare", data?.special_fare != null ? (settings.swipe_symbol ? data.special_fare + " " + settings.symbol : settings.symbol + " " + data.special_fare) : ""],
      ["Cancellation Fee", settings.swipe_symbol ? (data.cancellationFee ? data.cancellationFee : (0).toFixed(settings.decimal) + " " + settings.symbol) : settings.symbol + " " + (data.cancellationFee ? data.cancellationFee : (0).toFixed(settings.decimal))],
      ["Payment Gateway", data?.gateway],
      ["Payment Mode", data?.payment_mode ? data.payment_mode : ""],
      ["Cash Payment Amount", data?.cashPaymentAmount ? (settings.swipe_symbol ? data?.cashPaymentAmount + " " + settings.symbol : settings.symbol + " " + data?.cashPaymentAmount) : ""],
      ["Card Payment Amount", data?.cardPaymentAmount ? (settings.swipe_symbol ? data?.cardPaymentAmount + " " + settings.symbol : settings.symbol + " " + data?.cardPaymentAmount) : ""],
      ["Wallet Payment Amount", data?.usedWalletMoney ? (settings.swipe_symbol ? data?.usedWalletMoney + " " + settings.symbol : settings.symbol + " " + data?.usedWalletMoney) : ""],
      ["Payable Amount", data?.payableAmount ? (settings.swipe_symbol ? data?.payableAmount + " " + settings.symbol : settings.symbol + " " + data?.payableAmount) : ""],
    ];

    const csvContent = "data:text/csv;charset=utf-8," 
      + csvData.map(e => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "trip_details.csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "trip_details.csv"
  };

  const handlePdfDownload = () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text("Trip Details", 10, 17);
    doc.setFontSize(12); 
    doc.text("Taxi 2 Fly", 10, 22);
    
    // Add gap before details
    let yPosition = 35; // Adjusted starting position for details

    // Collect all details for the PDF
    const details = [
      { label: "Booking ID", value: data.id },
      { label: "Booking Reference", value: data.reference },
      { label: "Booking Status", value: t(data.status) },
      { label: "Booking Date", value: data?.bookingDate ? moment(data?.bookingDate).format("lll") : "" },
      { label: "Trip Start Date", value: data?.tripdate ? moment(data?.tripdate).format("lll") : "" },
      { label: "Trip Start Time", value: data?.trip_start_time },
      { label: "Trip End Time", value: data?.trip_end_time },
      { label: "Parcel Type", value: data.parcelTypeSelected ? `${data.parcelTypeSelected.description} (${data.parcelTypeSelected.amount})` : "" },
      { label: "Parcel Option", value: data.optionSelected ? `${data.optionSelected.description} (${data.optionSelected.amount})` : "" },
      { label: "Delivery Person", value: data?.deliveryPerson },
      { label: "Delivery Person Phone", value: data.deliveryPersonPhone ? (settings.AllowCriticalEditsAdmin ? data.deliveryPersonPhone : t("hidden_demo")) : "" },
      { label: "Trip Instructions", value: data?.tripInstructions }, 
      { label: "OTP", value: data?.otp },
      { label: "Total Time", value: data?.total_trip_time },
      { label: "Distance", value: data.distance ? (settings.convert_to_mile ? `${data?.distance} ${t("mile")}` : `${data?.distance} ${t("km")}`) : "" },
      { label: "Pickup Instructions", value: data?.pickUpInstructions },
      { label: "Delivery Instructions", value: data?.deliveryInstructions },
      { label: "Other Person", value: data?.otherPerson },
      { label: "Other Person Phone", value: data?.otherPersonPhone }, 
      { label: "Pickup Address", value: data?.pickupAddress },
      { label: "Drop Address", value: data?.dropAddress },
      { label: "Trip Cost", value: settings.swipe_symbol ? `${data?.trip_cost} ${settings.symbol}` : `${settings.symbol} ${data?.trip_cost}` },
      { label: "Customer Paid", value: data?.customer_paid ? (settings.swipe_symbol ? `${data?.customer_paid} ${settings.symbol}` : `${settings.symbol} ${data?.customer_paid}`) : "" },
      { label: "Discount Amount", value: settings.swipe_symbol ? `${data?.discount} ${settings.symbol}` : `${settings.symbol} ${data?.discount}` },
      // { label: "Driver Share", value: settings.swipe_symbol ? `${data?.driver_share} ${settings.symbol}` : `${settings.symbol} ${data?.driver_share}` },
      { label: "Fleet Admin Commission", value: data?.fleetCommission && parseFloat(data?.fleetCommission) > 0 ? (settings.swipe_symbol ? `${data?.fleetCommission} ${settings.symbol}` : `${settings.symbol} ${data?.fleetCommission}`) : "" },
      { label: "Van Fare", value: data?.special_fare != null ? (settings.swipe_symbol ? `${data.special_fare} ${settings.symbol}` : `${settings.symbol} ${data.special_fare}`) : "" },
      { label: "Payment Gateway", value: data?.gateway },
      { label: "Payment Mode", value: data?.payment_mode ? data.payment_mode : "" },
      { label: "Cash Payment Amount", value: data?.cashPaymentAmount ? (settings.swipe_symbol ? `${data?.cashPaymentAmount} ${settings.symbol}` : `${settings.symbol} ${data?.cashPaymentAmount}`) : "" },
      { label: "Card Payment Amount", value: data?.cardPaymentAmount ? (settings.swipe_symbol ? `${data?.cardPaymentAmount} ${settings.symbol}` : `${settings.symbol} ${data?.cardPaymentAmount}`) : "" },
      { label: "Wallet Payment Amount", value: data?.usedWalletMoney ? (settings.swipe_symbol ? `${data?.usedWalletMoney} ${settings.symbol}` : `${settings.symbol} ${data?.usedWalletMoney}`) : "" },
      { label: "Payable Amount", value: data?.payableAmount ? (settings.swipe_symbol ? `${data?.payableAmount} ${settings.symbol}` : `${settings.symbol} ${data?.payableAmount}`) : "" },
    ];

    details.forEach((detail) => {
      if (detail.value) {
        // Ensure the value is a string
        const valueString = String(detail.value); // Convert value to string
        
        // Add a line before each detail for separation
        doc.setDrawColor(200, 200, 200); // Light gray color for lines
        doc.line(10, yPosition + -1, 200, yPosition + -1); // Draw line
        
        // Set label and value positions
        const labelX = 10; // X position for label
        const valueX = 100; // X position for value (adjust as needed)
        
        // Add label
        doc.setTextColor(0, 0, 0); // Reset text color to black
        doc.text(`${detail.label}:`, labelX, yPosition + 5); // Label
        
        // Add value with text wrapping
        const wrappedValue = doc.splitTextToSize(valueString, 90); // Wrap text to fit within 90mm
        doc.setFont("helvetica", "italic"); // Italic for value
        wrappedValue.forEach((line, index) => {
          doc.text(line, valueX, yPosition + 5 + (index * 10)); // Value
        });
        
        doc.setFont("helvetica", "normal"); // Reset font style
        yPosition += 10 * wrappedValue.length; // Move down for the next line based on wrapped lines
      }
    });

    // Final line at the end of the details
    doc.line(10, yPosition + 2, 200, yPosition + 2); // Draw final line

    doc.save("trip_details.pdf"); // Save the PDF
  };

  const renderGridItem = (item, isRTL) => {
    return (
      <Grid
        key={item.key}
        container
        spacing={1}
        sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        style={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: 60,
          ...item.addressGridStyle
        }}
      >
        <Grid item xs={4}>
          <Typography
            style={{
              fontSize: 16,
              padding: 2,
              textAlign: isRTL === "rtl" ? "right" : "left",
              fontFamily:FONT_FAMILY,
              ...item.typographyStyleAddress
            }}
          >
            {item.label}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            style={{
              fontSize: 18,
              padding: 2,
              letterSpacing: -1,
              textAlign: "center",
              fontFamily:FONT_FAMILY,
            }}
          >
            -----
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            style={{
              fontSize: 16,
              padding: 2,
              textAlign: isRTL === "rtl" ? "left" : "right",
              wordBreak: 'break-word',
              fontFamily:FONT_FAMILY,
              ...item.style,
            }}
          >
            {item.value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderTypography = (text) => {
    return (
      <Typography
      className={classes.txt}
      style={{
        borderBottomRightRadius: isRTL ? "90px" : "",
        borderBottomLeftRadius: isRTL ? "" : "90px",
        fontFamily:FONT_FAMILY,
      }}
      variant="h5"
      >
        {text}
      </Typography>
    );
  };

  return (
    <>
      <div dir={isRTL === "rtl" ? "rtl" : "ltr"}
        style={{
          marginBottom: 20,
          display:"flex",
          justifyContent:"space-between"
        }}
      >
        <Button
          variant="text"
          onClick={() => {
            navigate("/completetrips");
          }}
        >
          <Typography
            style={{
              margin: "10px 10px 0 5px",
              textAlign: isRTL === "rtl" ? "right" : "left",
              fontWeight: "bold",
              color: MAIN_COLOR,
              fontFamily:FONT_FAMILY,
            }}
          >
            {`<<- ${t("go_back")}`}
          </Typography>
        </Button>
        {/* <Button
          variant="contained"
          onClick={handleExport}
          style={{ marginLeft: 10 }}
        >
          {t("export_details")}
        </Button> */}
        <Button
          variant="contained" 
          onClick={handlePdfDownload}
          style={{ marginLeft: 10 }}
        >
          <DownloadIcon style={{ marginRight: 5 }} /> 
        </Button>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Grid item>
              <Card className={classes.card}> 
              {renderTypography(t("ride_information"))}
                <Grid container direction="column" style={{ padding: 10 }}>
                  {data &&
                    [
                      // { key: "booking_id", label: t("booking_id"), value: data.id },
                      { key: "booking_ref", label: t("booking_ref"), value: data.reference },
                      {
                        key: "booking_status_web",
                        label: t("booking_status_web"),
                        value: t(data.status),
                        style: {
                          backgroundColor:
                          data?.status === "CANCELLED"
                                ? colors.RED
                                : data?.status === "COMPLETE"
                                  ? colors.GREEN
                                  : colors.YELLOW,
                          color:colors.WHITE,
                          fontWeight: "bold",
                          borderRadius: "10px",
                          textAlign: "center",
                          padding: 3,
                        },
                      },
                      {
                        key: "booking_date", label: t("booking_date"), value: data?.bookingDate
                          ? moment(data?.bookingDate).format("lll")
                          : ""
                      },
                      {
                        key: "trip_type", // New entry for Trip Type
                        label: t("Trip Type"), // Assuming you have a translation key for "trip_type"
                        value: data.roundTrip ? "Round Trip" : "One Way Trip", // Fetching roundTrip value
                      },
                      { key: "flightNumber", label: t("Flight Number"), value: data?.flightNumber },
                      {
                        key: "trip_start_date", label: t("trip_start_date"), value: data?.tripdate
                          ? moment(data?.tripdate).format("lll")
                          : ""
                      },
                      { key: "trip_start_time", label: t("trip_start_time"), value: data?.trip_start_time },
                      { key: "trip_end_time", label: t("trip_end_time"), value: data?.trip_end_time },
                      {
                        key: "parcel_type_web", label: t("parcel_type_web"), value: data.parcelTypeSelected
                          ? data.parcelTypeSelected.description +
                          " (" +
                          data.parcelTypeSelected.amount +
                          ")"
                          : ""
                      },
                      {
                        key: "parcel_option", label: t("parcel_option"), value: data.optionSelected
                          ? data.optionSelected.description +
                          " (" +
                          data.optionSelected.amount +
                          ")"
                          : ""
                      },
                      { key: "deliveryPerson", label: t("deliveryPerson"), value: data?.deliveryPerson },
                      {
                        key: "deliveryPersonPhone", label: t("deliveryPersonPhone"), value: data.deliveryPersonPhone ? (settings.AllowCriticalEditsAdmin
                          ? data.deliveryPersonPhone
                          : t("hidden_demo")) : ""
                      },
                      { key: "take_pickup_image_web", label: t("take_pickup_image_web"), value: data.pickup_image ? data.pickup_image : "", type: 'image', alt: "pickup_image" },
                      { key: "take_deliver_image_web", label: t("take_deliver_image_web"), value: data.deliver_image ? data.deliver_image : "", type: 'image', alt: "deliver_image" },
                      { key: "tripInstructions", label: t("tripInstructions"), value: data?.tripInstructions },
                      { key: "cancellation_reason", label: t("cancellation_reason"), value: data?.reason },
                      { key: "otp", label: t("otp"), value: data?.otp },
                      { key: "total_time", label: t("total_time"), value: data?.total_trip_time },
                      {
                        key: "distance_web", label: t("distance_web"), value: data.distance ? (settings.convert_to_mile
                          ? data?.distance + t("mile")
                          : data?.distance + " " + t("km")) : ""
                      },
                      { key: "pickUpInstructions_web", label: t("pickUpInstructions_web"), value: data?.pickUpInstructions },
                      { key: "deliveryInstructions", label: t("deliveryInstructions"), value: data?.deliveryInstructions },
                      { key: "otherPerson", label: t("otherPerson"), value: data?.otherPerson },
                      { key: "otherPersonPhone", label: t("otherPersonPhone"), value: data?.otherPersonPhone },
                      { key: "feedback", label: t("feedback"), value: data?.feedback },
                      {
                        key: "pickup_address", label: t("pickup_address"), value: data?.pickupAddress, addressGridStyle: {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                          minHeight: 60,
                          marginBottom: 20,
                        }, typographyStyleAddress: { color:colors.GREEN }
                      },
                      { key: "drop_address", label: t("drop_address"), value: data?.dropAddress, typographyStyleAddress: { color:colors.RED, } },
                    ].map((item) =>
                      item.type === "image" && item.value ? (
                        <Grid
                          key={item.key}
                          container
                          spacing={1}
                          sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: 60,
                          }}
                        >
                          <Grid item xs={4}>
                            <Typography
                              style={{
                                fontSize: 16,
                                padding: 2,
                                textAlign: isRTL === "rtl" ? "right" : "left",
                                fontFamily:FONT_FAMILY,
                              }}
                            >
                              {item.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              style={{
                                fontSize: 18,
                                padding: 2,
                                letterSpacing: -1,
                                textAlign: "center",
                                fontFamily:FONT_FAMILY,
                              }}
                            >
                              -----
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <img
                              src={item.value}
                              alt={item.alt}
                              style={{
                                width: 120,
                                height: 90,
                              }}
                            />
                          </Grid>
                        </Grid>
                      ) :
                        item.value ?renderGridItem(item, isRTL) : null
                    )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Grid item>
              <Card className={classes.card}>
                {renderTypography(t("payment_info"))}
                <Grid container direction="column" style={{ paddingRight: 15, paddingLeft: 15, paddingBottom: 15 }}>
                {data &&
                    [
                      {
                        key: "trip_cost",
                        label: t("Trip Cost"),
                        value: settings.swipe_symbol
                          ? ((data?.trip_cost || 0) - ((data?.roundTrip === "true" ? data?.tips / 2 : data?.tips) || 0) - (data?.special_fare || 0)) + " " + settings.symbol
                          : settings.symbol + " " + ((data?.trip_cost || 0) - ((data?.roundTrip === "true" ? data?.tips / 2 : data?.tips) || 0) -  (data?.special_fare || 0))
                      },
                      { key: "tips", label: t("Tips"), value: data?.roundTrip === "true" ? data?.tips / 2 : data?.tips },
                      {
                        key: "Customer_paid", label: t("Customer_paid"), value: data?.customer_paid ? (settings.swipe_symbol
                            ? data?.customer_paid + " " + settings.symbol
                            : settings.symbol + " " + data?.customer_paid) : ""
                      },
                      {
                        key: "discount_ammount",
                        label: t("discount_ammount"),
                        value: settings.swipe_symbol
                          ? data?.discount + " " + settings.symbol
                          : settings.symbol + " " + data?.discount,
                      },
                      data?.special_fare != null && {
                        key: "special_fare",
                        label: "Van Fare",
                        value: settings.swipe_symbol
                          ? data.special_fare + " " + settings.symbol
                          : settings.symbol + " " + data.special_fare
                      },
                      // {
                      //   key: "driver_share", label: t("driver_share"), value: settings.swipe_symbol
                      //     ? data?.driver_share + " " + settings.symbol
                      //     : settings.symbol + " " + data?.driver_share
                      // },
                      {
                        key: "fleet_admin_comission", label: t("fleet_admin_comission"), value: data?.fleetCommission && parseFloat(data?.fleetCommission) > 0 ? (settings.swipe_symbol
                          ? data?.fleetCommission + " " + settings.symbol
                          : settings.symbol + " " + data?.fleetCommission) : ""
                      },
                      // {
                      //   key: "convenience_fee", label: t("convenience_fee"), value: settings.swipe_symbol
                      //     ? data?.convenience_fees + " " + settings.symbol
                      //     : settings.symbol + " " + data?.convenience_fees
                      // },                      
                      {
                        key: "cancellationFee", label: t("cancellationFee"), value: settings.swipe_symbol
                          ? data.cancellationFee
                            ? data.cancellationFee
                            : (0).toFixed(settings.decimal) +
                            " " +
                            settings.symbol
                          : settings.symbol + " " + data.cancellationFee
                            ? data.cancellationFee
                            : (0).toFixed(settings.decimal)
                      },
                      {
                        key: "Total_trip_cost", label: t("Total Trip Cost"), value: settings.swipe_symbol
                          ? data?.trip_cost + " " + settings.symbol
                          : settings.symbol + " " + data?.trip_cost
                      },
                      {
                        key: "payment_gateway", label: t("payment_gateway"), value: data?.gateway
                      },
                      { key: "payment_mode_web", label: t("payment_mode_web"), value: data?.payment_mode ? data.payment_mode : "" },
                      {
                        key: "cash_payment_amount", label: t("cash_payment_amount"), value: data?.cashPaymentAmount ? (settings.swipe_symbol
                          ? data?.cashPaymentAmount + " " + settings.symbol
                          : settings.symbol + " " + data?.cashPaymentAmount) : ""
                      },
                      {
                        key: "card_payment_amount", label: t("card_payment_amount"), value:
                          data?.cardPaymentAmount ? (settings.swipe_symbol
                            ? data?.cardPaymentAmount + " " + settings.symbol
                            : settings.symbol + " " + data?.cardPaymentAmount) : ""
                      },
                      {
                        key: "wallet_payment_amount", label: t("wallet_payment_amount"), value:
                          data?.usedWalletMoney ? (settings.swipe_symbol
                            ? data?.usedWalletMoney + " " + settings.symbol
                            : settings.symbol + " " + data?.usedWalletMoney) : ""
                      },
                      {
                        key: "payable_ammount", label: t("payable_ammount"), value: data?.payableAmount ? (settings.swipe_symbol
                          ? data?.payableAmount + " " + settings.symbol
                          : settings.symbol + " " + data?.payableAmount) : ""
                      },
                    ].map((item) =>
                      item.value ? renderGridItem(item, isRTL): null
                    )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Grid item>
              <Card className={classes.card}>
                {renderTypography(t("driver_info"))}
                <Grid container direction="column" style={{ padding: 15 }}>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {data?.driver_image ? (
                      <Avatar
                        alt="driver profile image"
                        src={data?.driver_image}
                        style={{ width: 100, height: 100, objectFit: "cover" }}
                      />
                    ) : (
                      <AccountCircleIcon sx={{ width: 100, height: 100 }} />
                    )}
                  </Grid>
                  {data &&
                    [
                      { key: "driver_id", label: t("driver_id"), value: data?.driver ? data.driver : "" },
                      { key: "driver_name", label: t("driver_name"), value: data?.driver_name ? data.driver_name : "" },
                      {
                        key: "driver_contact", label: t("driver_contact"), value: data?.driver_contact ? (settings.AllowCriticalEditsAdmin
                          ? data?.driver_contact
                          : t("hidden_demo")) : ""
                      },
                      {
                        key: "driver_email", label: t("driver_email"), value: data?.driver_email ? (settings.AllowCriticalEditsAdmin
                          ? data?.driver_email
                          : t("hidden_demo")) : ""
                      },
                      { key: "car_type", label: t("car_type"), value: data?.carType ? data.carType : "" },
                      { key: "vehicle_no", label: t("vehicle_no"), value: data?.vehicle_number ? data.vehicle_number : "" },
                      { key: "fleetadmins", label: t("fleetadmins"), value: data?.fleetadmin && role === 'admin' ? data.fleetadmin : "" },
                      { key: "device_id", label: t("device_id"), value: data?.driverDeviceId ? data.driverDeviceId : "" },

                    ].map((item) =>
                      item.value ? renderGridItem(item, isRTL): null
                    )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Grid item>
              <Card className={classes.card}>
                {renderTypography(t("customer_info"))}
                <Grid container direction="column" style={{ padding: 15 }}>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {data?.customer_image ? (
                      <Avatar
                        alt="customer profile image"
                        src={data?.customer_image}
                        style={{ width: 100, height: 100, objectFit: "cover" }}
                      />
                    ) : (
                      <AccountCircleIcon sx={{ width: 100, height: 100 }} />
                    )}
                  </Grid>
                  {data &&
                    [
                      { key: "customer_id", label: t("customer_id"), value: data?.customer ? data.customer : "" },
                      { key: "customer_name", label: t("customer_name"), value: data?.customer_name ? data.customer_name : "" },
                      {
                        key: "customer_contact", label: t("customer_contact"), value: data?.customer_contact ? (settings.AllowCriticalEditsAdmin
                          ? data?.customer_contact
                          : t("hidden_demo")) : ""
                      },
                      {
                        key: "customer_email", label: t("customer_email"), value: data?.customer_email ? (settings.AllowCriticalEditsAdmin
                          ? data?.customer_email
                          : t("hidden_demo")) : ""
                      },
                      { key: "noteText", label: t("Note"), value: data?.noteText },

                    ].map((item) =>
                      item.value ? renderGridItem(item, isRTL): null
                    )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default CompletetripsDetail;
